import { I18n } from "i18n-js";

async function loadTranslations(i18n) {
    const response = await fetch(window.translations_url);
    const translations = await response.json();
    i18n.store(translations);
    return i18n
}
const i18n = new I18n();


loadTranslations(i18n);
i18n.locale = window.locale;

export default i18n