import * as ActiveStorage from "@rails/activestorage"
import { Turbo } from "@hotwired/turbo-rails"
import "lato-font"
import "@fontsource/bodoni-moda";

import "./application.css"
import "./flash.css"

import "lightgallery.js/dist/css/lightgallery.min.css"
import {Application} from '@hotwired/stimulus'
import StimulusControllerResolver, { createViteGlobResolver } from 'stimulus-controller-resolver'
import Rollbar from "rollbar"

import 'animate.css';
import './components/collapsible.ts'
import moment from "moment"
import fr from 'moment/dist/locale/fr';

moment.locale('fr', fr);


import '../utils/i18n'

console.info('Vite ⚡️ Rails');


moment.updateLocale("en", { week: {
        dow: 6, // First day of week is Saturday
    }}
);

moment.locale(window.locale)

ActiveStorage.start();

Turbo.setProgressBarDelay(10);

const application = Application.start();

StimulusControllerResolver.install(application, createViteGlobResolver(
    import.meta.glob(['../controllers/*_controller.{js,jsx,ts,tsx}']),
    import.meta.glob(['../../components/**/*_controller..{js,jsx,ts,tsx}'])
));

if (import.meta.env.ROLLBAR_CLIENT_ACCESS_TOKEN) {
    if (import.meta.env.RAILS_ENV === "production") {
        new Rollbar({
            accessToken: import.meta.env.ROLLBAR_CLIENT_ACCESS_TOKEN,
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
                code_version: '1.0.0',
                environment: import.meta.env.RAILS_ENV,
            }
        });
    }
}

document.addEventListener("turbo:load", () => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("turbo-reload")) {
        params.delete("turbo-reload");
        const p = params.toString();
        const suffix = "" === p ? "" : `?${p}`
        window.history.replaceState({}, "", decodeURIComponent(`${window.location.pathname}${suffix}`));
        document.querySelector("meta[name='turbo-visit-control']").remove()
    }
});


// Monkeypatch to fix turbo issue of wrong turbo-frame
// see: https://github.com/hotwired/turbo/pull/579
document.addEventListener('turbo:before-fetch-request', (event) => {
    const targetTurboFrame = event.target.getAttribute('data-turbo-frame');
    const fetchTurboFrame = event.detail.fetchOptions.headers['Turbo-Frame'];
    if (targetTurboFrame && targetTurboFrame != fetchTurboFrame && document.querySelector(`turbo-frame#${targetTurboFrame}`)) {
        event.detail.fetchOptions.headers['Turbo-Frame'] = targetTurboFrame;
    }
});


/**
 * Stops flatpickr to replicate
 * https://www.betterstimulus.com/turbo/teardown.html
 */
document.addEventListener('turbo:before-cache', () => {
    application.controllers.forEach(controller => {
        if (typeof controller.teardown === 'function') {
            controller.teardown()
        }
    })
})



